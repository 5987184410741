module.exports = {
  titleSearch: 'ឧបករណ៍ទាញយកវីដេអូបន្ដបន',
  placeholderSearch: 'ដាក់តំណរវីដេអូរបស់អ្នកនៅទីនេះ',
  download: 'ទាញយក',
  howtodownload: 'តើធ្វើដូចម្តេចដើម្បីទាញយក?',
  seeInstructions: 'មើលការណែនាំ',
  loadingSearch: 'កំពុងដំណើរការតំណរដើម្បីទាញយក។ សូមនៅនៅលើទំព័រនេះ',
  thumbnail: 'រូបតំណាង',
  audio: 'អូឌីយោ',
  errorLink: 'តំណរទាញយកមិនត្រូវបានរកឃើញទេ។',
  errorFormat: 'ទ្រង់ទ្រាយ URL មិនត្រឹមត្រូវទេ',
  platform: 'សារជាឧបត្ថម្',
  downloadFalse: 'ការទាញយកបរាជ័យដោយសារកំហុសនៅក្នុងឯកសារ។ សូមជ្រើសរើសទ្រង់ទ្រាយផ្សេងទៀត។',
  loading: 'កំពុងដំណើរការ',
};
