module.exports = {
  titleSearch: '在线视频下载工具',
  placeholderSearch: '将您的视频链接粘贴在这里',
  download: '下载',
  howtodownload: '如何下载？',
  seeInstructions: '查看说明',
  loadingSearch: '正在处理链接以下载。请保持在此页面',
  thumbnail: '缩略图',
  audio: '音频',
  errorLink: '未找到下载链接。',
  errorFormat: '无效的 URL 格式',
  platform: '支持资源',
  downloadFalse: '下载失败，原因是文件错误。请选用其他格式。',
  loading: '加载中',
};
