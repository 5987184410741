module.exports = {
  titleSearch: 'Ferramenta de Download de Vídeo Online',
  placeholderSearch: 'Cole o link do seu vídeo aqui',
  download: 'Baixar',
  howtodownload: 'Como baixar?',
  seeInstructions: 'Ver instruções',
  loadingSearch: 'Processando link para download. Por favor, permaneça nesta página',
  thumbnail: 'Miniatura',
  audio: 'Áudio',
  errorLink: 'Link para download não encontrado.',
  errorFormat: 'Formato de URL inválido',
  platform: 'Recursos de suporte',
  downloadFalse:
    'Falha no download devido a erro de arquivo. Por favor, escolha um formato diferente.',
  loading: 'Carregando',
};
