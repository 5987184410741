module.exports = {
  titleSearch: 'Online Video Download Tool',
  placeholderSearch: 'Paste your video link here',
  download: 'Download',
  howtodownload: 'How to download?',
  seeInstructions: 'See instructions',
  loadingSearch: 'Processing link to download. Please stay on this page',
  thumbnail: 'Thumbnail',
  audio: 'Audio',
  errorLink: 'Download link not found.',
  errorFormat: 'Invalid URL format',
  platform: 'Support Resources',
  downloadFalse: 'Download failed due to file error. Please choose a different format.',
  loading: 'Loading',
  title404: 'Page Not Found',
  des404: 'The page may have been removed or the URL is incorrect',
  back: 'Go Back',
  home: 'Home',
};
