module.exports = {
  titleSearch: 'Online-Video-Download-Tool',
  placeholderSearch: 'Fügen Sie hier Ihren Videolink ein',
  download: 'Herunterladen',
  howtodownload: 'Wie herunterzuladen?',
  seeInstructions: 'Anleitung ansehen',
  loadingSearch: 'Verarbeite Link zum Download. Bitte bleiben Sie auf dieser Seite',
  thumbnail: 'Vorschaubild',
  audio: 'Audio',
  errorLink: 'Download-Link nicht gefunden.',
  errorFormat: 'Ungültiges URL-Format',
  platform: 'Unterstützungsressourcen',
  downloadFalse:
    'Download fehlgeschlagen aufgrund einer Dateifehler. Bitte wählen Sie ein anderes Format.',
  loading: 'Laden',
};
