/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* import { routerRedux } from 'dva/router';
import { message } from 'antd'; */
// import log from '@/utils/log';
import {
  queryByLinkfacebook,
  queryByLinktiktok,
  queryByLinkdouyin,
  queryByLinkyoutube,
  queryByLinkprintest,
  queryByLinkinstagram,
  queryByLinktwiter,
} from '@/services/downloadRedux';

export default {
  namespace: 'download',
  state: {},
  effects: {
    *fetchByLinkfacebook({ payload, callback }, { call }) {
      const response = yield call(queryByLinkfacebook, payload);
      if (callback) callback(response);
    },
    *fetchByLinktiktok({ payload, callback }, { call }) {
      const response = yield call(queryByLinktiktok, payload);
      if (callback) callback(response);
    },
    *fetchByLinkdouyin({ payload, callback }, { call }) {
      const response = yield call(queryByLinkdouyin, payload);
      if (callback) callback(response);
    },
    *fetchByLinkyoutube({ payload, callback }, { call }) {
      const response = yield call(queryByLinkyoutube, payload);
      if (callback) callback(response);
    },
    *fetchByLinkprintest({ payload, callback }, { call }) {
      const response = yield call(queryByLinkprintest, payload);
      if (callback) callback(response);
    },
    *fetchByLinkinstagram({ payload, callback }, { call }) {
      const response = yield call(queryByLinkinstagram, payload);
      if (callback) callback(response);
    },
    *fetchByLinktwiter({ payload, callback }, { call }) {
      const response = yield call(queryByLinktwiter, payload);
      if (callback) callback(response);
    },
  },
  reducers: {},
};
