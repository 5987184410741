module.exports = {
  titleSearch: 'オンライン動画ダウンロードツール',
  placeholderSearch: 'ここに動画のリンクを貼り付けてください',
  download: 'ダウンロード',
  howtodownload: 'ダウンロードするには？',
  seeInstructions: '指示を見る',
  loadingSearch: 'ダウンロードするためにリンクを処理しています。このページにとどまってください',
  thumbnail: 'サムネイル',
  audio: 'オーディオ',
  errorLink: 'ダウンロードリンクが見つかりません。',
  errorFormat: 'URL形式が無効です',
  platform: 'サポートリソース',
  downloadFalse:
    'ファイルエラーのためダウンロードに失敗しました。別のフォーマットを選択してください。',
  loading: '読み込み中',
};
