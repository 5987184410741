module.exports = {
  titleSearch: 'ऑनलाइन वीडियो डाउनलोड टूल',
  placeholderSearch: 'यहां अपने वीडियो लिंक को पेस्ट करें',
  download: 'डाउनलोड',
  howtodownload: 'डाउनलोड कैसे करें?',
  seeInstructions: 'निर्देश देखें',
  loadingSearch: 'डाउनलोड के लिए लिंक संसाधित कर रहा है। कृपया इस पृष्ठ पर बने रहें',
  thumbnail: 'थंबनेल',
  audio: 'ऑडियो',
  errorLink: 'डाउनलोड लिंक नहीं मिला।',
  errorFormat: 'अमान्य URL स्वरूप',
  platform: 'समर्थन संसाधन',
  downloadFalse: 'डाउनलोड असफल, फ़ाइल त्रुटि के कारण। कृपया एक अलग प्रारूप चुनें।',
  loading: 'लोड हो रहा है',
};
