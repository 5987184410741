module.exports = {
  titleSearch: 'أداة تحميل الفيديو عبر الإنترنت',
  placeholderSearch: 'الصق رابط الفيديو الخاص بك هنا',
  download: 'تحميل',
  howtodownload: 'كيفية التحميل؟',
  seeInstructions: 'شاهد التعليمات',
  loadingSearch: 'جارٍ معالجة الرابط للتحميل. يرجى البقاء على هذه الصفحة',
  thumbnail: 'صورة مصغرة',
  audio: 'صوت',
  errorLink: 'لم يتم العثور على رابط التحميل.',
  errorFormat: 'تنسيق URL غير صالح',
  platform: 'العربية',
  downloadFalse: 'فشل التنزيل بسبب خطأ في الملف. يرجى اختيار تنسيق مختلف.',
  loading: 'جارٍ التحميل',
};
