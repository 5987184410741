module.exports = {
  titleSearch: '온라인 비디오 다운로드 도구',
  placeholderSearch: '여기에 비디오 링크를 붙여넣으세요',
  download: '다운로드',
  howtodownload: '어떻게 다운로드하나요?',
  seeInstructions: '지침 보기',
  loadingSearch: '다운로드할 링크를 처리하는 중입니다. 이 페이지에 머무르세요',
  thumbnail: '썸네일',
  audio: '오디오',
  errorLink: '다운로드 링크를 찾을 수 없습니다.',
  errorFormat: '잘못된 URL 형식',
  platform: '지원 자원',
  downloadFalse: '파일 오류로 다운로드 실패. 다른 형식을 선택하세요.',
  loading: '로딩 중',
};
