module.exports = {
  titleSearch: 'เครื่องมือดาวน์โหลดวิดีโอออนไลน์',
  placeholderSearch: 'วางลิงก์วิดีโอของคุณที่นี่',
  download: 'ดาวน์โหลด',
  howtodownload: 'จะดาวน์โหลดได้อย่างไร?',
  seeInstructions: 'ดูคำแนะนำ',
  loadingSearch: 'กำลังประมวลผลลิงก์เพื่อดาวน์โหลด โปรดอยู่บนหน้านี้',
  thumbnail: 'ภาพขนาดย่อ',
  audio: 'เสียง',
  errorLink: 'ไม่พบลิงก์ดาวน์โหลด.',
  errorFormat: 'รูปแบบ URL ไม่ถูกต้อง',
  platform: 'ทรัพยากรสนับสนุน',
  downloadFalse: 'ดาวน์โหลดล้มเหลวเนื่องจากข้อผิดพลาดของไฟล์ กรุณาเลือกฟอร์แมตอื่น',
  loading: 'กำลังโหลด',
};
