module.exports = {
  titleSearch: 'Outil de Téléchargement de Vidéo en Ligne',
  placeholderSearch: 'Collez le lien de votre vidéo ici',
  download: 'Télécharger',
  howtodownload: 'Comment télécharger ?',
  seeInstructions: 'Voir les instructions',
  loadingSearch: 'Traitement du lien pour le téléchargement. Veuillez rester sur cette page',
  thumbnail: 'Miniature',
  audio: 'Audio',
  errorLink: 'Lien de téléchargement introuvable.',
  errorFormat: "Format d'URL invalide",
  platform: 'Ressources de support',
  downloadFalse:
    "Échec du téléchargement en raison d'une erreur de fichier. Veuillez choisir un autre format.",
  loading: 'Chargement',
};
