module.exports = {
  titleSearch: 'Alat Unduh Video Online',
  placeholderSearch: 'Tempelkan tautan video Anda di sini',
  download: 'Unduh',
  howtodownload: 'Cara mengunduh?',
  seeInstructions: 'Lihat instruksi',
  loadingSearch: 'Memproses tautan untuk diunduh. Silakan tetap di halaman ini',
  thumbnail: 'Gambar mini',
  audio: 'Audio',
  errorLink: 'Tautan unduhan tidak ditemukan.',
  errorFormat: 'Format URL tidak valid',
  platform: 'Sumber daya dukungan',
  downloadFalse: 'Unduhan gagal karena kesalahan file. Silakan pilih format lain.',
  loading: 'Memuat',
};
