import { stringify } from 'qs';
import request from '@/utils/request';
import CONFIG from '@/utils/config';
// import log from '@/utils/log';

// user
// eslint-disable-next-line import/prefer-default-export
export async function queryMenus(params) {
  const { filter, sort, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'ASC']),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  // console.log(`${CONFIG.API_SERVER_WEB}/menus/find/all/parent-child?${stringify(query)}`);
  return request(`${CONFIG.API_SERVER_WEB}/menus/find/all/parent-child?${stringify(query)}`);
}

export async function queryCategoryInfo(id, languagesId) {
  // console.log(`${CONFIG.API_SERVER_WEB}/categories/${id}`)

  return request(`${CONFIG.API_SERVER_WEB}/categories/${id}?languagesId=${languagesId}`);
}
export async function queryCategoryInfoByName(name, sitesId, languagesId) {
  const query = {
    filter: JSON.stringify({ sitesId, status: '1' }),
  };
  // console.log(`${CONFIG.API_SERVER_WEB}/categories/${name}?${stringify(query)}&languagesId=${languagesId}`)
  return request(
    `${CONFIG.API_SERVER_WEB}/categories/${name}?${stringify(query)}&languagesId=${languagesId}`
  );
}

export async function queryObjectLists(params) {
  const { filter, sort } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['orderBy', 'DESC']),
    // attributes: 'id,name,urlSlugs,image,isHome,descriptions,orderBy,orderHome,typesId'
  };
  // console.log(`CONFIG.API_SERVER_WEB}/categories?${stringify(query)}`);
  return request(`${CONFIG.API_SERVER_WEB}/objectLists?${stringify(query)}`);
}

export async function queryArticleList(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['dateCreated', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  // console.log(`${CONFIG.API_SERVER_WEB}/articles?${stringify(query)}`)
  return request(`${CONFIG.API_SERVER_WEB}/articles?${stringify(query)}`);
}

export async function queryAd(params) {
  const { filter, attributes, range } = params;
  const query = {
    filter: JSON.stringify(filter),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  // console.log(`${CONFIG.API_SERVER_WEB}/ads?${stringify(query)}`)
  return request(`${CONFIG.API_SERVER_WEB}/ads?${stringify(query)}`);
}

export async function queryArticleInfoByName(name, filter) {
  const query = {
    filter: JSON.stringify(filter),
  };
  // console.log(`${CONFIG.API_SERVER_WEB}/articles/${name}?${stringify(query)}`);

  return request(`${CONFIG.API_SERVER_WEB}/articles/${name}?${stringify(query)}`);
}

export async function queryChildrenCategoryAll(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  // console.log(`${CONFIG.API_SERVER_WEB}/categories/find/list/parent-child?${stringify(query)}`);

  return request(`${CONFIG.API_SERVER_WEB}/categories/find/list/parent-child?${stringify(query)}`);
}
export async function queryTreeCategoryById(params) {
  const { filter, sort, range, attributes } = params;
  const query = {
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort || ['name', 'DESC']),
    range: JSON.stringify(range || [0, 49]),
  };
  if (attributes) {
    query.attributes = attributes;
  }
  return request(
    // console.log(`${CONFIG.API_SERVER_WEB}/categories/find/getbycategories/parent-child?${stringify(query)}`)
    `${CONFIG.API_SERVER_WEB}/categories/find/getbycategories/parent-child?${stringify(query)}`
  );
}
export async function queryDataSiteUrl(url) {
  return request(`${CONFIG.API_SERVER_WEB}/sites/${url}`);
}
export async function addcontacts(params) {
  return request(`${CONFIG.API_SERVER_WEB}/contacts`, {
    method: 'POST',
    body: params,
  });
}
