module.exports = {
  titleSearch: 'Công cụ Tải video trực tuyến',
  placeholderSearch: 'Dán liên kết đến video của bạn vào đây',
  download: 'Tải về',
  howtodownload: 'Làm thế nào để tải về?',
  seeInstructions: 'Xem hướng dẫn',
  loadingSearch: 'Đang xử lý liên kết để tải về. Vui lòng ở lại trang này',
  thumbnail: 'Ảnh',
  audio: 'Audio',
  errorLink: 'Liên kết tải xuống không được tìm thấy.',
  errorFormat: 'Định dạng URL không hợp lệ',
  platform: 'Tài nguyên hỗ trợ',
  downloadFalse: 'Tải xuống thất bại do file lỗi. Vui lòng chọn định dạng khác',
  loading: 'Đang tải',
  title404: 'Không tìm thấy trang',
  des404: 'Trang đã bị xóa hoặc địa chỉ url không đúng',
  back: 'Trở về',
  home: 'Trang chủ',
};
