module.exports = {
  titleSearch: 'Инструмент для загрузки видео онлайн',
  placeholderSearch: 'Вставьте ссылку на ваше видео сюда',
  download: 'Скачать',
  howtodownload: 'Как скачать?',
  seeInstructions: 'Смотреть инструкции',
  loadingSearch: 'Обработка ссылки для загрузки. Пожалуйста, оставайтесь на этой странице',
  thumbnail: 'Эскиз',
  audio: 'Аудио',
  errorLink: 'Ссылка для скачивания не найдена.',
  errorFormat: 'Неверный формат URL',
  platform: 'Ресурсы поддержки',
  downloadFalse: 'Не удалось скачать из-за ошибки файла. Пожалуйста, выберите другой формат.',
  loading: 'Загрузка',
};
