module.exports = {
  titleSearch: 'Herramienta de Descarga de Video en Línea',
  placeholderSearch: 'Pega el enlace de tu video aquí',
  download: 'Descargar',
  howtodownload: '¿Cómo descargar?',
  seeInstructions: 'Ver instrucciones',
  loadingSearch: 'Procesando enlace para descargar. Por favor, permanece en esta página',
  thumbnail: 'Miniatura',
  audio: 'Audio',
  errorLink: 'Enlace de descarga no encontrado.',
  errorFormat: 'Formato de URL inválido',
  platform: 'Recursos de soporte',
  downloadFalse:
    'Descarga fallida debido a un error de archivo. Por favor, elige un formato diferente.',
  loading: 'Cargando',
};
