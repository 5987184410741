import router from 'next/router';
import nextCookie from 'next-cookies';
import cookie from 'js-cookie';
import publicRuntimeConfig from '@/utils/config';
import moment from 'moment';

export const calculateExpiryTime = (minutes) => {
  const now = new Date();
  const expiryTime = new Date(now.getTime() + minutes * 60000); // 60000 miliseconds = 1 minute
  return expiryTime;
}

export const login = async ({ token }) => {
  const cookieExpire = Number(publicRuntimeConfig.TOKEN_LOGIN_EXPIRE || 1440);
  cookie.set('token', token, { expires: calculateExpiryTime(cookieExpire) });
  cookie.set('timeLogin', moment(), { expires: calculateExpiryTime(cookieExpire) });
  window.location.reload()
};


export const clearLogout = () => {
  cookie.remove('token');
  cookie.remove('timeLogin');
  cookie.remove('mobile');
  cookie.remove('country');
  if (typeof window !== 'undefined') {
    localStorage.clear();
    sessionStorage.clear();
    window.localStorage.setItem('logout', Date.now());
  }
};

export const logout = () => {
  window.location.reload();
  clearLogout();
};

export const loggedIn = ctx => {
  const { token } = nextCookie(ctx);
  if (ctx.req && token) {
    ctx.res.writeHead(302, { Location: `/` });
    ctx.res.end();
    return;
  }

  if (token) {
    router.push('/index', `/`);
  }

  // eslint-disable-next-line consistent-return
  return token;
};

export const auth = ctx => {
  const { token } = nextCookie(ctx);
  // log("withAuth auth token: ", token)
  if (ctx.req && !token) {
    ctx.res.writeHead(302, { Location: '/' });
    ctx.res.end();
    return;
  }

  if (!token) {
    window.location.href = '/';
  }

  // eslint-disable-next-line consistent-return
  return token;
};

export const tokenExpired = ctx => {
  // log("withAuth auth token: ", token)

  if (ctx.req) {
    ctx.res.clearCookie('token');
    ctx.res.writeHead(302, { Location: '/' });
    ctx.res.end();
    return;
  }
  cookie.remove('token');
  window.location.href = '/';

  // eslint-disable-next-line consistent-return
  return null;
};

