module.exports = {
  titleSearch: 'Strumento di Download Video Online',
  placeholderSearch: 'Incolla il link del tuo video qui',
  download: 'Scarica',
  howtodownload: 'Come scaricare?',
  seeInstructions: 'Vedi istruzioni',
  loadingSearch: 'Elaborazione del link per il download. Si prega di rimanere su questa pagina',
  thumbnail: 'Miniatura',
  audio: 'Audio',
  errorLink: 'Link per il download non trovato.',
  errorFormat: 'Formato URL non valido',
  platform: 'Risorse di supporto',
  downloadFalse:
    'Download fallito a causa di un errore del file. Per favore, scegli un formato diverso.',
  loading: 'Caricamento',
};
