/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* import { routerRedux } from 'dva/router';
import { message } from 'antd'; */
// import log from '@/utils/log';
import {
  queryMenus,
  queryCategoryInfo,
  queryObjectLists,
  queryArticleList,
  queryArticleInfoByName,
  queryAd,
  queryCategoryInfoByName,
  queryChildrenCategoryAll,
  queryTreeCategoryById,
  queryDataSiteUrl,
  addcontacts,
} from '@/services/websRedux';

export default {
  namespace: 'webs',

  state: {
    dataAll: [],
    dataAdd: [],
    dataMenu: {},
    info: {},
    query: {},
    filter: {},
    dataCategoryInfo: {},
    sts: [],
    datainfoArticle: {},
    dataAd: [],
    dataSite: {},
    dataProductInfo: {},
    dataCheckout: {},
    dataThemeInfo: {},
  },
  effects: {
    *addcontacts({ payload, callback }, { call }) {
      const response = yield call(addcontacts, payload);
      if (callback) callback(response);
    },
    *showLogin({ payload, callback }, { put }) {
      console.log('1');
      yield put({
        type: 'changeStatusLogin',
      });
    },
    *fetchMenus({ payload, callback }, { call, put }) {
      const response = yield call(queryMenus, payload);
      // console.log('servie model fetch response: %o ', response);
      if (response) {
        yield put({
          type: 'save',
          payload: response || {},
        });
      }
      if (callback) callback(response);
    },
    *fetchObjectLists({ payload, callback }, { call, put }) {
      // console.log('payload', payload);
      const response = yield call(queryObjectLists, payload);
      // const page = yield select(state => state.users.page);
      // console.log('response', response);
      if (response) {
        yield put({ type: 'saveObjectLists', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchListArticle({ payload, callback }, { call }) {
      const response = yield call(queryArticleList, payload);
      if (callback) callback(response);
    },
    *fetchAllAd({ payload, callback }, { call }) {
      const response = yield call(queryAd, payload);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      // if (response) {
      //   yield put({ type: 'saveAd', payload: response || {} });
      // }
      if (callback) callback(response);
    },
    *fetchArticleInfoByName(
      {
        payload: { name, filter, noRedux },
        callback,
      },
      { call, put }
    ) {
      const response = yield call(queryArticleInfoByName, name, filter);
      // const page = yield select(state => state.users.page);
      // console.log('dsasad', response?.id);
      if (response && !noRedux) {
        yield put({ type: 'saveArticleInfo', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchCategoryInfoByName(
      {
        payload: { name, sitesId, languagesId },
        callback,
      },
      { call, put }
    ) {
      // console.log('1');
      const response = yield call(queryCategoryInfoByName, name, sitesId, languagesId);
      // const page = yield select(state => state.users.page);
      // console.log("dsasad", response)
      if (response) {
        yield put({ type: 'saveCategoryInfo', payload: response || {} });
        // yield put({ type: 'saveLangid', payload: response || {} });
      }
      if (callback) callback(response);
    },

    *fetchAllChildrenCategory({ payload, callback }, { call, put }) {
      const response = yield call(queryChildrenCategoryAll, payload);
      // const page = yield select(state => state.users.page);
      // console.log('dsasad', response);
      if (callback) callback(response);
    },
    *fetchTreeCategory({ payload, callback }, { call }) {
      const response = yield call(queryTreeCategoryById, payload);
      if (callback) callback(response);
    },
    *fetchSiteUrl(
      {
        payload: { url },
        callback,
      },
      { call, put }
    ) {
      const response = yield call(queryDataSiteUrl, url);
      if (response) {
        yield put({ type: 'saveDataSiteUrl', payload: response || {} });
      }
      // console.log('====================================');
      // console.log(response);
      // console.log('====================================');
      if (callback) callback(response);
    },
    *fetchCategoryInfoById(
      {
        payload: { id, languagesId },
        callback,
      },
      { call }
    ) {
      const response = yield call(queryCategoryInfo, id, languagesId);
      if (callback) callback(response);
    },
  },

  reducers: {
    changeStatusLogin(state) {
      return { ...state, statusLogin: !state.statusLogin };
    },
    saveAd(state, action) {
      return { ...state, dataAd: action.payload.result };
    },
    save(state, action) {
      return { ...state, dataMenu: action.payload.result };
    },
    saveArticleInfo(state, action) {
      return { ...state, datainfoArticle: action.payload };
    },
    saveCategoryInfo(state, action) {
      return { ...state, dataCategoryInfo: action.payload };
    },
    saveObjectLists(state, action) {
      // console.log(action.payload);
      return { ...state, dataObjectLists: action.payload };
    },
    saveDataSiteUrl(state, action) {
      return {
        ...state,
        dataSite: action.payload && action.payload.result,
      };
    },
    saveProductInfo(state, action) {
      return { ...state, dataProductInfo: action.payload };
    },
    saveThemeInfo(state, action) {
      return { ...state, dataThemeInfo: action.payload };
    },
    saveCheckout(state, action) {
      return { ...state, dataCheckout: action.payload };
    },
  },
};
